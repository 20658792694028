import React from 'react';
import { Helmet } from "react-helmet";
import { AdvantagesSection } from '../components/Landing/AdvantagesSection/AdvantagesSection';
import { Footer } from '../components/Footer/Footer';
import { Header } from '../components/Header/Header';
import { HeroSection } from '../components/Landing/HeroSection/HeroSection';
import { HowItWorksSection } from '../components/Landing/HowItWorksSection/HowItWorksSection';
import { OfferSection } from '../components/Landing/OfferSection/OfferSection';
import { WhereToShare } from '../components/Landing/WhereToShare/WhereToShare';
import { ILP } from '../types';

interface IndustryLpProps {
	pageContext: ILP;
}

const IndustryLp: React.FC<IndustryLpProps> = (({ pageContext }) => {
	return (
		<>
			<Helmet>
				<title>Wizyter.pl</title>
			</Helmet>
			<div>
				<Header />
				<HeroSection sectionData={pageContext.heroSection} />
				<HowItWorksSection />
				<AdvantagesSection sectionData={pageContext.advantagesSection} />
				<WhereToShare sectionData={pageContext.whereToShareSection} />
				<OfferSection sectionData={pageContext.offerSection} />
				<Footer socials={pageContext.socials} />
			</div>
		</>
	)
});

export default IndustryLp;